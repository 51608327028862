footer {
  width: calc(100%-150px);
  height: 125px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: black;
  margin-left: 150px;
}

#info-tech-block p {
  color: white;
  padding: 2px 0;
}

#footer-link-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer-link-li {
  list-style-type: none;
  padding: 2px 0;
}

.footer-link {
  color: white;
}

@media screen and (max-width: 800px) {
  footer {
    width: 100%;
    margin-left: 0;
  }
}