nav {
  width: 149px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid black;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9;
}
  
.logo-image {
  width: 100px;
  height: 100px;
  padding: 15px;
}

#nav-button-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.nav-button {
  font-size: 1.5rem;
  padding: 5px 0px 5px 10px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
}

.active {
  border-bottom: 2px solid black;
}

@media screen and (max-width: 800px) {
  nav {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
  }

  .logo-image {
    width: 50px;
    height: 50px;
    padding: 0 30px;
  }

  #nav-button-list {
    flex-direction: row;
    justify-content: center;
    margin: 0 30px;
  }

  .nav-button {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 5px 10px;
    margin: 0px 10px;
  }
}

@media screen and (max-width: 400px) {
  nav {
    padding: 0;
  }
  
  #nav-button-list {
		width: 200px;
	}

	.nav-button {
		margin: 0;
  }
}

@media screen and (max-width: 300px) {
  nav {
    padding: 0;
  }

  .logo-image {
    padding: 0 15px;
  }
  
  #nav-button-list {
		width: 200px;
	}

	.nav-button {
		padding: 5px; 
  }
}