#project-portfolio {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 150px;
}

.project-block {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	margin: 50px 50px;
}

.project-image-block {
	margin: 20px;
}

.project-preview-image {
	width: 400px;
	height: 250px;
	border-radius: 15px;
	padding: 10px;
	border: 1px solid grey;
	box-shadow: 2px 2px 1px black;
}

.project-info-block {
	width: 400px;
	height: 250px;
}

.project-title {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 5px;
}

.project-description {
	font-size: 1.3rem;
	margin-bottom: 20px;
}

.project-tech-stack {
	font-size: 1.3rem;
	margin-bottom: 5px;
}

.tech-stack-label {
	font-weight: 600;
}

.project-link {
	display: block;
	width: 125px;
	font-size: 1.2rem;
	color: red;
}

@media screen and (max-width: 1025px) {
	.project-block {
		flex-direction: column;
	}
}

@media screen and (max-width: 800px) {
	#project-portfolio {
		margin: 160px 50px 0 50px;
	}
}

@media screen and (max-width: 450px) {
	#project-portfolio {
		margin: 160px 25px 0 25px;
	}

	.project-block {
		width: 100%;
	}

	.project-image-block {
		margin: 0;
	}

	.project-preview-image {
		width: 300px;
		height: 188px;
	}

	.project-info-block {
		width: 300px;
		margin: 30px 0 50px 0;
	}

}

@media screen and (max-width: 350px) {
	#project-portfolio {
		margin: 160px 5px 0 5px;
	}

	.project-block {
		width: 100%;
	}

	.project-image-block {
		margin: 0;
	}

	.project-preview-image {
		width: 250px;
		height: 156px;
	}

	.project-info-block {
		width: 250px;
		margin: 30px 0 50px 0;
	}

}

