#landing-page {
	margin-left: 150px;
	width: auto;
	height: calc(100vh - 125px);
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

h1 {
	font-size: 2rem;
  color: black;
}

.jumbotron a {
	text-decoration: none;
	color: #000;
}

#name-block {
	width: 350px;
	font-family: 'Oswald', sans-serif;
	font-weight: 700;
}

#first-name {
	display: block;
	font-size: 5rem;
	letter-spacing: .4rem;
}

#middle-name {
	display: block;
	font-size: 5rem;
	letter-spacing: 1rem;
}

#last-name {
	display: block;
	font-size: 5rem;
	letter-spacing: 2rem;
}

#job-title {
	font-size: 2.4rem;
}

#profile-image {
	width: 325px;
	height: 325	px;
	border-radius: 15px;
	padding: 10px;
	border: 1px solid grey;
	box-shadow: 8px 7px 12px #808080;
	transform: rotate(-15deg);
}

@media screen and (max-width: 950px) {

	#first-name {
		font-size: 4rem;
		letter-spacing: .4rem;
	}
	
	#middle-name {
		font-size: 4rem;
		letter-spacing: 1rem;
	}
	
	#last-name {
		font-size: 4rem;
		letter-spacing: 2rem;
	}
	
	#job-title {
		font-size: 2rem;
	}
	
	#profile-image {
		width: 250px;
		height: 250px;
		margin-right: 50px;
	}
}

@media screen and (max-width: 800px) {
	#landing-page {
		margin-left: 0px;
		padding: 0 50px;
		justify-content: center;
	}

	#profile-image {
		width: 250px;
		height: 250px;
		margin-right: 0px;

	}
}

@media screen and (max-width: 650px) {
	#landing-page {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
	
	#name-block {
		width: 300px;
		padding-top: 50px;
	}

	#profile-image-block {
		margin-top: 50px;
	}

	#profile-image {
		width: 200px;
		height: 200px;
	}
}

@media screen and (max-width: 550px) {
	#landing-page {
		margin-top: 75px;
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 350px) {
	#name-block {
		padding-top: 25px;
	}
}

@media screen and (max-width: 300px) {
	#profile-image-block {
		margin-top: 0;
	}
	
	#name-block {
		width: 250px;
	}

	#first-name {
		font-size: 3rem;
		letter-spacing: .4rem;
	}
	
	#middle-name {
		font-size: 3rem;
		letter-spacing: .8rem;
	}
	
	#last-name {
		font-size: 3rem;
		letter-spacing: 1.6rem;
	}
	
	#job-title {
		font-size: 1.6rem;
	}

	#profile-image {
		width: 175px;
		height: 175px;
	}
}